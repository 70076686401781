<template>
  <div>
    <a-modal
        :visible="visible"
        :title="title"
        @cancel="handleCancel"
        :maskClosable="false"
        width="60%"
        :footer="null"

    >
      <a-form-model
          ref="searchForm"
          :model="searchForm"
          layout="inline"
          :rules="rules"
      >
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="项目名称" prop="projectName">
              <div class="input-box">
                <a-input
                    v-model.trim="searchForm.projectName"
                    allow-clear
                    placeholder="请输入"
                    style="width: 450px;"
                    :maxLength="50"
                />
                <div class="size">{{ (searchForm.projectName || '').length }}/50</div>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" class="p-20">
            <a-form-model-item label="品牌" prop="principalId">
              <a-select
                  v-model="searchForm.principalId"
                  :default-active-first-option="false"
                  option-filter-prop="children"
                  show-search
                  placeholder="请输入"
                  style="width: 400px"
                  @change="handlePrincipalChange"
              >
                <a-select-option
                    v-for="item in principalList"
                    :key="item.value"
                    :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <!--<a-range-picker
              :placeholder="['开始时间', '结束时间']"
              :allowClear="false"
              show-time
              format="YYYY-MM-DD"
              inputReadOnly
              @change="onChangeDate"
              style="width:300px"
              v-model="searchForm.time"
              :disabled-date="handleDisabledDate"
            />-->

            <ASpace>
              <a-form-model-item style="margin-right: 0;" label="起止时间" prop="startDate">
                <a-date-picker
                    v-model="searchForm.startDate"
                    :disabled-date="handleDisabledStart"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="开始时间"
                    @change="onChangeDate"
                    allow-clear
                />
              </a-form-model-item>
              <span>~</span>
              <a-form-model-item style="margin-right: 0;" label="" prop="endDate">
                <a-date-picker
                    v-model="searchForm.endDate"
                    :disabled-date="handleDisabledDate"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="结束时间"
                    :open="endTimeOpen"
                    :disabled="!searchForm.startDate"
                    @openChange="handleEndOpenChange"
                    allow-clear
                />
              </a-form-model-item>
              <span
                  style="padding-left: 6px"
                  v-if="is_edit"
              >起止时间必须包含所有周期，<a href="#" @click="goToKPISetPage">点我调整考核周期</a></span>
            </ASpace>
          </a-col>
          <a-col :span=" 24" class="p-20">
            <a-form-model-item label="负责人" prop="ownerId">
              <a-select
                  v-model="searchForm.ownerId"
                  show-search
                  style="width: 360px"
                  placeholder="请输入"
                  option-filter-prop="children"
              >
                <a-select-option
                    v-for="item in commanderIdList"
                    :key="item.userid"
                    :value="item.userid"
                >
                  {{ item.employeeName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span=" 24" class="p-20">
            <a-form-model-item label="是否对客"  prop="dataUsageScope">
              <a-select
                  v-model="searchForm.dataUsageScope"
                  style="width: 360px"
                  placeholder="请选择"
              >
              <a-select-option :key="1">是</a-select-option>
              <a-select-option :key="0">否</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" class="p-20">
            <a-form-model-item label="项目描述">
              <div class="textarea-box">
                <a-textarea
                    v-model="searchForm.projectDescription"
                    placeholder="请输入描述"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    :maxLength="300"
                    show-count
                    style="width: 330px"
                />
                <div class="size">{{ (searchForm.projectDescription || '').length }}/300</div>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <template solt="footer">
          <div style="padding: 40px 0 20px 20% ;border-top: 1px solid #cccccc">
            <base-button
                :title="'取消'"
                @onClickBtn="handleCancel"
                style="margin-right: 20px"
            >
            </base-button>
            <base-button
                :type="'primary'"
                :title="'保存'"
                :loading="is_submit"
                :disabled="is_submit"
                @onClickBtn="handleProjectSubmit"
            >
            </base-button>
          </div>
        </template>
      </a-form-model>
    </a-modal>

    <assessmentCycleDrawer
        :visible="assessmentCycleVisible"
        :project-id="id"
        :cycle-list="cycleList"
        :project_data="project"
        @handleCancel="assessmentCycleVisible = false;"
    />
  </div>
</template>

<script>
import _https from "@/api/workOrder";
import _API from "@/api/projectManagement";
import { cloneDeep, pick } from "lodash-es";
import assessmentCycleDrawer from "../components/assessmentCycleDrawer";
import moment from "moment/moment";

export default {
  name: "projectModal",
  components: {assessmentCycleDrawer},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "新建项目",
    },
    id: {
      type: [String, Number],
    },
    principalList: {
      type: Array,
      default() {
        return [];
      },
    },
    project_data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      searchForm: {
        endDate: null,
        dataUsageScope: 0,
      },
      rules: {
        projectName: [{
          required: true, message: "请输入项目名称", trigger: "blur",
        }],
        principalId: [{
          required: true, message: "请选择", trigger: "change",
        }],
        ownerId: [{
          required: true, message: "请选择", trigger: "change",
        }],
        endDate: [{
          required: true, message: "请选择结束时间", trigger: "change",
        }],
        startDate: [{
          required: true, message: "请选择开始时间", trigger: "change",
        }],
        dataUsageScope: [{
          required: true, message: "请选择是否对客", trigger: "change",
        }],
      },
      commanderIdList: [],
      is_submit: false,
      is_edit: false,
      backTime: {},
      endTimeOpen: false,
      assessmentCycleVisible: false,
      cycleList: [],
      list: [],
      endTimeFive: "",
      project: "",
    };
  },
  watch: {
    visible: {
      handler() {
        // this.backTime = cloneDeep(this.$options.data().backTime);
      },
      deep: true,
    },
    project_data: {
      handler() {
        this.project = JSON.stringify(this.project_data);
        this.getCycleList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getTreeData();
    if(this.id) {
      this.is_edit = true;
      this.getProjectDisplay();
    }
  },

  methods: {
    getCycleList() {
      _API.getCycleList({kpiBusinessId: this.project_data.kpiBusinessId}).then((res) => {
        if(res.code == 200) {
          this.list = res.data || [];
        } else {
          this.$message.error(res.message || "获取列表失败");
        }
      });
    },
    getProjectDisplay() {
      _API.getProjectDisplay(this.id).then((res) => {
        if(res.code == 200) {
          this.$set(this, "searchForm", res.data || {});
          this.$set(this, "backTime", pick(res.data, ["cycleStartDate", "cycleEndDate"]));
        } else {
          this.$message.error(res.message || "回显失败");
        }
      });
    },
    // 获取节点数据
    getTreeData() {
      _https.getTreeData().then((res) => {
        if(res.code == 200) {
          let arr = this.getAllChildren(res.data.children || []);
          this.commanderIdList = arr;
        } else {
          this.$message.error(res.message || "获取数据失败");
        }
      }).catch(() => {
        this.treeLoading = false;
      });
    },
    handleCancel() {
      this.$emit("handleCancel");
      this.searchForm = {};
    },
    handlePrincipalChange() {
    },
    //日期选择框
    onChangeDate(_date) {
      this.searchForm.endDate = null;
      /*if(!date) {
        return;
      }*/

      this.endTimeOpen = true;

      /*let begin_time = "";
      let end_time = "";
      if (dates[0]) {
        begin_time = this.$moment(dates[0]._d).format("YYYY-MM-DD");
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD");
      }
      this.searchForm.endDate = end_time;
      // + " 00:00:00";
      this.searchForm.startDate = begin_time;
      // + " 23:59:59";*/
    },
    handleProjectSubmit() {
      this.$refs.searchForm.validate(valid => {
        if(valid) {
          _API.saveProject(this.searchForm).then((res) => {
            if(res.code == 200) {
              this.$message.success("保存成功");
              this.handleCancel();
            } else {
              this.$message.error(res.message || "保存失败");
            }
          });
        }
      });
    },
    getAllChildren(data) {
      let res = [];
      data?.forEach((item) => {
        res = [...res, ...item.employees];
        if(item.children.length) {
          res = [...res, ...this.getAllChildren(item.children)];
        }
      });

      return res;
    },
    handleDisabledStart(current) {
      const {cycleStartDate} = this.backTime;
      if(cycleStartDate) {
        return current?.valueOf() > +new Date(cycleStartDate);
      }
      return false;
    },
    // 处理起止时间禁用日期
    handleDisabledDate(current) {
      const {cycleEndDate} = this.backTime;
      const {startDate} = this.searchForm;

      if(cycleEndDate) return current.valueOf() < +new Date(cycleEndDate) || current.valueOf() < (startDate && (+new Date(startDate)));

      return current.valueOf() < (startDate && (+new Date(startDate)));

      /*if(cycleEndDate) {
        return current?.valueOf() < (cycleEndDate && +new Date(cycleEndDate));
      }
      return current?.valueOf() < this.searchForm.startDate ? (+new Date(this.searchForm.startDate)) : false;*/
    },
    handleEndOpenChange(open) {
      this.endTimeOpen = open;
    },
    showAssessmentCycleVisible() {
      this.cycleList = [];
      if(this.list.length) {
        this.list.forEach((item) => {
          if(item.isAllCycle == 0) {
            this.cycleList.push(cloneDeep(item));
          }
        });
      } else {
        for(let i = 0; i < 3; i++) {
          let obj = {
            cycleName: `第${ i + 1 }周期`,
            startDate: "",
            endDate: "",
            days: 0,
            businessId: this.project_data.kpiBusinessId,
            isAllCycle: 0,
          };
          this.cycleList.push(obj);
        }
      }
      this.assessmentCycleVisible = true;
    },
    goToKPISetPage() {
      let route = this.$router.resolve({
        path: "/KPIAssessmentSetting",
        query: {
          projectId: this.id,
          kpiBusinessId: this.project_data.kpiBusinessId,
          // project_data: this.project,
        },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.p-20 {
  padding: 15px 0;
}
/deep/ .input-box {
  position: relative;
  .ant-input {
    padding-right: 80px;
  }
  .size {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
  }
}
/deep/ .textarea-box {
  position: relative;
  .ant-input {
    padding-bottom: 20px;
  }
  .size {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 22px;
    line-height: 1.5;
    background: #fff;
    text-align: right;
  }
}
</style>
