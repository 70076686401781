<template>
  <a-drawer
      :visible="visible"
      class="custom-class"
      :title="title"
      placement="right"
      width="64%"
      @close="handleCancel"
  >
    <a-form-model
        ref="cycle_form"
        :model="cycle_form"
    >
      <p>提示：考核周期不能超出项目起止时间，且周期与周期之间不可交叉。</p>
      <p style="padding-left: 43px;">自定义周期是以时间范围划分周期（非固定），自定义周是以周维度划分周期（相对固定）。</p>

      <a-radio-group v-model="activeTab" button-style="solid" style="margin: 20px 0;" @change="onChangeTab">
          <a-radio-button value="1">
            自定义周期
          </a-radio-button>
          <a-radio-button value="2">
            自定义周
          </a-radio-button>
          <a-radio-button value="3">
            日
          </a-radio-button>
      </a-radio-group>
      <div v-if="activeTab == 1">
        <div class="table-content">
          <a-row style="margin-bottom: 20px;background: rgb(245 245 245);padding: 10px">
            <a-col :span="5">
              <span class="title">周期名称</span>
            </a-col>
            <a-col :span="12">
              <span class="title">时间范围</span>
            </a-col>
            <a-col :span="3">
              <span class="title">天数</span>
            </a-col>
            <a-col :span="4">
              <span class="title">操作</span>
            </a-col>
          </a-row>
          <div v-for="(item,index) of cycle_form.cycleList" :key="index">
            <a-row style="padding: 10px 0">
              <a-col :span="5">
                <a-form-model-item
                    label=""
                    :prop="`cycleList.${index}.cycleName`"
                    :rules="[
                      {required: true, message: '请输入周期名称', trigger: 'blur'}
                    ]"
                >
                  <a-input
                      v-model="item.cycleName"
                      style="width: 70%"
                      :maxLength="10"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <div style="display: flex;align-items: center">
                  <a-form-model-item
                      label=""
                      :prop="`cycleList.${index}.startDate`"
                      :rules="[
                      {required: true, message: '请选择开始时间', trigger: 'change'}
                    ]"
                  >
                    <a-date-picker
                        v-model="item.startDate"
                        :disabled-date="evn => handleStartDisTime(item, index, evn)"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        placeholder="开始时间"
                        @openChange="(open) => !open && $set(item, '__open__', true)"
                        style="min-width: 140px; width: 140px;"
                        @change="handleComputationInterval(item); $set(item, 'endDate', null)"
                    />
                  </a-form-model-item>
                  <a-form-model-item
                      label=""
                      :prop="`cycleList.${index}.endDate`"
                      :rules="[
                      {required: true, message: '请选择结束时间', trigger: 'change'}
                    ]"
                  ><span style="padding: 4px">~</span>
                    <a-date-picker
                        v-model="item.endDate"
                        :disabled-date="(evn) => handleEndDisTime(item, index, evn)"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        placeholder="结束时间"
                        :disabled="!item.startDate"
                        :open="item.__open__"
                        @openChange="(open) => $set(item, '__open__', open)"
                        style="min-width: 140px; width: 140px;"
                        @change="handleComputationInterval(item)"
                    />
                  </a-form-model-item>
                </div>
              </a-col>
              <a-col :span="3">
                <span style="line-height: 40px;">{{ item.days }}</span>
              </a-col>
              <a-col :span="4">
                <a href="#" style="line-height: 40px;" @click="handleBtn(1,index)">删除</a>
              </a-col>
            </a-row>
          </div>
          <div>
            <a-button
                type="dashed"
                icon="plus"
                block
                style="width:70%;margin: 40px 0;"
                @click="handleBtn(0)">
              添加
            </a-button>
          </div>
        </div>
      </div>
      <div v-if="activeTab == 2">
          <a-form-model-item label="按周考核">
              <a-switch v-model="cycle_form.isShowWeek" />
          </a-form-model-item>
          <a-form-model-item
              label="一周开始日"
              prop="customWeekStartDay"
              extra="每周数据统计将按该设置划分周期。"
              v-if="cycle_form.isShowWeek"
          >
            <a-select
              v-model="cycle_form.customWeekStartDay"
            >
              <a-select-option
                v-for="item in dayList"
                :key="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
      </div>
      <div v-if="activeTab == 3">
          <a-form-model-item label="按日考核">
              <a-switch v-model="cycle_form.isShowDay" />
          </a-form-model-item>
      </div>
    </a-form-model>
    <template solt="footer">
      <div class="flex-end">
        <base-button
            :title="'取消'"
            @onClickBtn="handleCancel"
            style="margin-right: 20px"
        >
        </base-button>
        <base-button
            :type="'primary'"
            :title="'保存'"
            :loading="is_submit"
            @onClickBtn="handleSubmit"
        >
        </base-button>
      </div>
    </template>
  </a-drawer>
</template>

<script>
import _API from "@/api/projectManagement";
import moment from "moment";
import { cloneDeep, flattenDeep, isEmpty, min, range, max } from "lodash-es";

const stringToObject = (data) => {
  try {
    return JSON.parse(data);
  } catch(e) {
    return {};
  }
};

function getDiffDays(start, end) {
  return range(start, end, 1000 * 3600 * 24);
}

export default {
  name: "assessmentCycleDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "设置考核周期",
    },
    cycleList: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: [String, Number],
    },
    date5: {
      type: String,
    },
    project_data: {
      type: String,
      default: "",
    },
    weekData: {
      type: Object,
      default: () => {},
    },
    dayData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      is_submit: false,
      project: {},
      // 获取当前时间的每一天
      diffDays: {},
      cycle_form: {
        cycleList: [],
        isShowWeek: undefined,
        isShowDay: undefined,
      },
      activeTab: '1',
      dayList: [
        { id: 1, label: '星期一' },
        // { id: 2, label: '星期二' },
        // { id: 3, label: '星期三' },
        // { id: 4, label: '星期四' },
        // { id: 5, label: '星期五' },
        // { id: 6, label: '星期六' },
        // { id: 7, label: '星期日' },
      ],
    };
  },
  watch: {
    project_data: {
      handler() {
        // console.log(this.project_data);
        this.project = stringToObject(this.project_data);
        this.calculateStartAndEndDays();
      },
      deep: true,
      immediate: true,
    },
    cycleList: {
      handler(val) {
        // console.log(val);
        this.$set(this.cycle_form, "cycleList", (val || []).map(v => ({...v, __open__: false})));
      },
      deep: true,
    },
    weekData: {
      handler(val) {
        this.$set(this.cycle_form, "isShowWeek", val.isShowWeek ? true : false);
      },
      deep: true,
    },
    dayData: {
      handler(val) {
        this.$set(this.cycle_form, "isShowDay", val.isShowDay ? true : false);
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    // 计算可选的开始结束时间
    calculateStartAndEndDays() {
      const {endDate, startDate} = this.project;
      if(!endDate && !startDate) return;
      const today = moment().startOf("date");
      // 今天时间减6个月的时间
      const firstSixMonthsFormToday = cloneDeep(today).subtract(6, "month");
      // 今天加5天的时间
      const lastFiveDaysFormToday = cloneDeep(today).add(6, "days");
      // 实例化已有的开始时间
      const sTime = startDate && moment(startDate);
      // 获取可选的开始时间
      // const start = (firstSixMonthsFormToday < sTime ? sTime : firstSixMonthsFormToday).startOf("date");
      const start = sTime.startOf("date");
      // 实例化已有的结束时间
      const eTime = endDate && moment(endDate);
      // 获取可选的结束时间
      // eslint-disable-next-line no-unused-vars
      const end = (lastFiveDaysFormToday > eTime ? eTime : lastFiveDaysFormToday).endOf("date");

      this.diffDays = {
        start,
        end: eTime,
      };

      // // 获取每一天
      // getDiffDays(start.valueOf(), end.valueOf()).forEach(day => {
      //   this.diffDays[day] = {
      //     disabled: false,
      //     max: end.valueOf(),
      //   }
      // });
      //
      // console.log(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), '开始结束时间');
    },

    // 处理限制开始时间
    handleStartDisTime(item, index, value) {
      item = cloneDeep(item);
      const {start, end} = cloneDeep(this.diffDays);
      const val = moment(value).startOf("date").valueOf();
      // 获取已经选了的日期
      const checked = flattenDeep(
          this.cycle_form.cycleList
              .filter(
                  (v, i) => (!(isEmpty(v.startDate) || isEmpty(v.endDate)) && i !== index),
              )
              .map(
                  v => getDiffDays(moment(v.startDate).startOf("date"), moment(v.endDate).endOf("date")),
              ),
      );
      // 大于总开始时间 || 小于总结束时间 || 未被选中
      return start > val || end < val || checked.includes(val);
    },
    // 处理限制结束时间
    handleEndDisTime(item, index, value) {
      item = cloneDeep(item);
      const {start, end} = this.diffDays;
      const val = moment(value).startOf("date").valueOf();
      const itemStart = item.startDate ? moment(item.startDate).startOf("date").valueOf() : null;
      // 获取已经选了的日期
      const checked = flattenDeep(
          this.cycle_form.cycleList
              .filter(
                  (v, i) => (!(isEmpty(v.startDate) || isEmpty(v.endDate)) && i !== index),
              )
              .map(
                  v => getDiffDays(moment(v.startDate).startOf("date"), moment(v.endDate).endOf("date")),
              ),
      );
      // 获取离开始时间之后最近的时间
      const maxDay = min(checked.filter(v => (v > itemStart)));
      // 获取限制时间戳
      const toMaxDay = maxDay ? moment(maxDay).valueOf() : null;
      // 大于总开始时间 || 小于总结束时间 || 未被选中 || 大于当前已选择的开始时间 || 小于临近的已选时间
      return start > val || end < val || checked.includes(val) || itemStart > val || (itemStart && maxDay && val > toMaxDay);
    },

    // 计算区间
    handleComputationInterval(item) {
      const {startDate, endDate} = item;

      if(startDate && endDate) {
        const days = moment(endDate).diff(moment(startDate), "d") + 1;
        this.$set(item, "days", days < 0 ? 0 : days);
      }
    },

    handleCancel() {
      this.$emit("handleCancel");
    },
    handleSubmit() {
      this.$refs.cycle_form.validate((valid, obj) => {
        if(valid) {
          if(this.activeTab == 1) {
            // 获取全部已选时间
            const allDays = flattenDeep(this.cycle_form.cycleList.map(v => v.startDate && v.endDate ? [moment(v.startDate).valueOf(), moment(v.endDate).valueOf()] : []));
            // 获取已选时间的最小值做开始时间
            const startDate = moment(min(allDays)).format("YYYY-MM-DD");
            // 获取已选时间的最大值做结束时间
            const endDate = moment(max(allDays)).format("YYYY-MM-DD");
            if(!this.cycle_form.cycleList.length) {
              this.$message.error("至少添加一个周期！");
              return;
            }
            const data = this.date5 || this.project.endDate;
            if(this.project.projectStatus == 3 || moment().subtract(1, "days") >= moment(data)) {
              this.$message.error("抱歉！已超过限定修改日期！");
              return;
            }
            let arr = cloneDeep(this.cycle_form.cycleList);
            arr.push({
              startDate,
              endDate,
              cycleName: "全周期",
              days: moment(endDate).diff(moment(startDate), "d") + 1,
              isAllCycle: 1,
              businessId: this.project.businessId,
            });
            const list = arr.map(item => {
              delete item.__open__;
              return item;
            });
            this.saveCycles(list);
          } else if(this.activeTab == 2) {
            this.saveWeek()
          }  else if(this.activeTab == 3) {
            this.saveDay()
          } 
        }
      });
    },
    handleBtn(type, index) {
      let that = this;
      switch(type) {
        case 0:
          let obj = {
            cycleName: "",
            startDate: "",
            endDate: "",
            days: 0,
            businessId: this.project.businessId,
            isAllCycle: 0,
            __open__: false,
          };
          this.cycle_form.cycleList.push(obj);
          // console.log("添加");
          break;
        case 1:
          that.$confirm({
            title: "删除考核周期会影响KPI结果，以及与该周期关联的跟进人员关系，确定要删除吗？",
            okText: "删除",
            cancelText: "取消",
            onOk() {
              that.cycle_form.cycleList.splice(index, 1);
            },
          });
          // console.log("删除");
          break;
      }
    },
    saveCycles(list) {
      this.is_submit = true;
      let params = {
        list,
        businessId: this.project.kpiBusinessId,
      };
      _API.saveCycles(params).then((res) => {
        if(res.code == 200) {
          this.$emit('saveCycles')
          this.handleCancel();
        } else {
          this.$message.error(res.message || "保存失败");
        }
        this.is_submit = false;
      });
    },
    saveWeek() {
      this.is_submit = true;
      let params = {
        // customWeekStartDay: this.cycle_form.customWeekStartDay,
        // customWeekStartDay: '星期一',
        businessId: this.project.kpiBusinessId,
        isShowWeek: this.cycle_form.isShowWeek ? 1 : 0
      };
      _API.setOperateWeek(params).then((res) => {
        if(res.code == 200) {
          this.handleCancel();
        } else {
          this.$message.error(res.message || "保存失败");
        }
        this.is_submit = false;
      });
    },
    saveDay() {
      this.is_submit = true;
      let params = {
        businessId: this.project.kpiBusinessId,
        isShowDay: this.cycle_form.isShowDay ? 1 : 0,
      };
      _API.setOperateDay(params).then((res) => {
        if(res.code == 200) {
          this.handleCancel();
        } else {
          this.$message.error(res.message || "保存失败");
        }
        this.is_submit = false;
      });
    },
    onChangeTab() {
      this.cycle_form.customWeekStartDay = this.activeTab == 1 ? undefined : 1
    }
  },
};
</script>

<style scoped lang="less">
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 40px 0 20px 20%;
  border-top: 1px solid #cccccc
}

.table-content {
  padding: 15px;
  height: calc(100vh - 300px);
  overflow: auto;

  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
}
</style>
